import { useLayoutEffect } from 'react'

interface TelegramEnvironmentProps {
	children?: React.ReactNode
}

export function TelegramEnvironment({ children }: TelegramEnvironmentProps) {
	useLayoutEffect(() => {
		window.Telegram.WebApp.expand()
		window.Telegram.WebApp.enableClosingConfirmation()
		window.Telegram.WebApp.disableVerticalSwipes()
		window.Telegram.WebApp.setHeaderColor('#04332c')
	}, [])

	return <>{children}</>
}
