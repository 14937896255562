import classNames from 'classnames'
import { useEffect, useState } from 'react'

import { Button } from '../../Button/Button'
import { Icon } from '../../Icon/Icon'

import c from './InputRange.module.scss'

interface TRange {
	min?: number
	max?: number
	step?: number

	value?: number | null
	onChange?(value: number): void

	className?: string
}

export function InputRange({
	min = 0,
	max = 100,
	value = 0,
	step = 10,
	onChange,
	className,
	...inputProps
}: InputRangeProps) {
	const [inputValue, setInputValue] = useState<number>(value ?? min)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setInputValue(value ?? min), [value])

	const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = Number(e.target.value)

		setInputValue(val)
		if (onChange) onChange(val)
	}

	// When "+" button clicked
	const onIncValue = () => {
		let val = inputValue + step
		if (val > max) val = max

		setInputValue(val)
		if (onChange) onChange(val)
	}

	// When "-" button clicked
	const onDecValue = () => {
		let val = inputValue - step
		if (val < min) val = min

		setInputValue(val)
		if (onChange) onChange(val)
	}

	// Calculating slider bar fill width & thumb position
	const fillPercent = ((inputValue - min) / (max - min)) * 100
	const barInnerWidth = `${fillPercent}%`
	const barThumbPosition = `calc(${fillPercent}% - 0.0225rem * ${fillPercent})`

	return (
		<div className={classNames(c['input-range'], className)}>
			<input
				className={c['range-slider']}
				onChange={onChangeInput}
				type='range'
				min={min}
				max={max}
				value={inputValue}
				{...inputProps}
			/>
			<div className={c['input-range__bar']}>
				<div className={c['input-range__bar--inner']} style={{ width: barInnerWidth }} />
				<div className={c['input-range__bar--thumb']} style={{ left: barThumbPosition }}>
					<Icon name='input-burger' w={28} h={21} className={c['input-range__bar--thumb-icon']} />
				</div>
			</div>

			<Button size='sm' color='yellow' className={c['input-range__btn']} onClick={onDecValue}>
				<span>-</span>
			</Button>
			<Button size='sm' color='yellow' className={c['input-range__btn']} onClick={onIncValue}>
				<span>+</span>
			</Button>
		</div>
	)
}

type InputRangeProps = TRange & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof TRange>
