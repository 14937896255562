import cn from 'classnames'

import { useGetCardSprite } from '@/entities/files'

import { Card } from '../../model/game.types'

import c from './PlayerCard.module.scss'

export type PlayerCardProps = {
	isPlayer?: boolean
} & Card

export function PlayerCard({ name, in_combo, isPlayer = false }: PlayerCardProps) {
	const spriteURL = useGetCardSprite(name)

	return (
		<div className={cn(c['player-card'], in_combo && c['highlighted'], isPlayer && c['player'])}>
			<img width={60} height={84} src={spriteURL} />
		</div>
	)
}
