// Actions
import * as filesActions from './model/files.actions'

export const useFilesActions = () => ({ ...filesActions })

// Store
export { useFilesStore } from './model/files.store'
export * from './model/files.types'

export { useGetCardSprite } from './hooks/useGetCardSprite'
