import { Overlay, useCreateOverlay, useOverlay } from '@/components/Overlay'
import { SupportPage } from '@/components/Support'

import { Button, Icon } from '@/shared/ui'

import { ExitWindow } from '../ExitWindow/ExitWindow'

import c from './GameHeader.module.scss'

export function GameHeader() {
	useCreateOverlay('exit-overlay')
	useCreateOverlay('support-overlay')

	const [exitOverlay, toggleExitOverlay] = useOverlay('exit-overlay')
	const [supportOverlay, toggleSupportOverlay] = useOverlay('support-overlay')

	const onShowSupport = () => toggleSupportOverlay(true)
	const onShowExit = () => toggleExitOverlay(true)

	return (
		<div className={c['game-header']}>
			<Button type='ghost' size='sm'>
				<Icon name='exit' w={30} onClick={onShowExit} />
			</Button>

			<Button type='ghost' size='sm' onClick={onShowSupport}>
				<Icon name='rank' w={30} />
			</Button>

			{exitOverlay && <Overlay children={<ExitWindow />} transparent smooth />}
			{supportOverlay && <Overlay children={<SupportPage />} />}
		</div>
	)
}
