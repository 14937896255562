import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enCommon from '@/shared/locales/en/common.json'
import enSupport from '@/shared/locales/en/support.json'

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: {
				...enCommon,
				...enSupport
			}
		}
		// Add more languages here
		// ru: { translation: ruCommon }
	},

	lng: 'en',
	fallbackLng: 'en',
	interpolation: { escapeValue: false }
})

export default i18n
