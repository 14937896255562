import cn from 'classnames'

import { useGetCardSprite } from '@/entities/files'

import { Icon } from '@/shared/ui'

import { Card } from '../../model/game.types'

import c from './PlayingCard.module.scss'

export function PlayingCard({ name, in_combo }: Card) {
	const spriteURL = useGetCardSprite(name)

	return (
		<div className={cn(c['card'], in_combo && name !== 'PLUG' && c['highlighted'])}>
			{name === 'PLUG' && (
				<div className={c['card--facedown']}>
					<Icon name='card-shirt-icon' className={c['card--facedown-icon']} />
				</div>
			)}
			{name !== 'PLUG' && <img width={120} height={168} src={spriteURL} />}
		</div>
	)
}
