import c from './Chip.module.scss'

interface ChipProps {
	w?: string
	content?: string | number
	color?: string
}

export function Chip({ w = '1.5625rem', color = 'var(--color-black-0)', content }: ChipProps) {
	const rules =
		typeof content === 'string'
			? {
					x: '50%',
					y: '50%',
					dx: '0.1rem',
					dy: '0.825rem',
					fontSize: `calc(${(parseFloat(w) / 100) * 45}rem + 1.6rem)`
				}
			: {
					x: '46%',
					y: '50%',
					dx: '0.1rem',
					dy: '0.825rem',
					fontSize: `calc(${(parseFloat(w) / 100) * 40}rem + 1.6rem)`
				}

	return (
		<div className={c['chip-wrapper']} style={{ width: w, height: w, color }}>
			<svg
				width={w}
				height={w}
				viewBox='0 0 100 100'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M100 50C100 77.6142 77.6142 100 50 100C22.3858 100 2.65895e-08 77.6142 2.65895e-08 50C2.65895e-08 22.3858 22.3858 0 50 0C77.6142 0 100 22.3858 100 50Z'
					fill='white'
				/>
				<path
					d='M48 20.075C41.4391 20.5081 35.2047 23.0953 30.265 27.435L18.935 16.105C26.8959 8.77991 37.1918 4.50781 48 4.045V20.075Z'
					fill='currentColor'
				/>
				<path
					d='M69.735 27.435C64.7953 23.0953 58.5609 20.5081 52 20.075V4.045C62.8082 4.50781 73.1042 8.77991 81.065 16.105L69.735 27.435Z'
					fill='currentColor'
				/>
				<path
					d='M79.925 48C79.4919 41.4391 76.9047 35.2047 72.565 30.265L83.895 18.935C91.2201 26.8959 95.4922 37.1918 95.955 48H79.925Z'
					fill='currentColor'
				/>
				<path
					d='M72.565 69.735C76.9047 64.7953 79.4919 58.5609 79.925 52H95.955C95.4922 62.8082 91.2201 73.1041 83.895 81.065L72.565 69.735Z'
					fill='currentColor'
				/>
				<path
					d='M52 79.925C58.5609 79.4919 64.7953 76.9046 69.735 72.565L81.065 83.895C73.1042 91.2201 62.8082 95.4922 52 95.955V79.925Z'
					fill='currentColor'
				/>
				<path
					d='M30.265 72.565C35.2047 76.9046 41.4391 79.4919 48 79.925V95.955C37.1918 95.4922 26.8959 91.2201 18.935 83.895L30.265 72.565Z'
					fill='currentColor'
				/>
				<path
					d='M20.075 52C20.5081 58.5609 23.0954 64.7953 27.435 69.735L16.105 81.065C8.77994 73.1041 4.50784 62.8082 4.04502 52H20.075Z'
					fill='currentColor'
				/>
				<path
					d='M27.435 30.265C23.0954 35.2047 20.5081 41.4391 20.075 48H4.04502C4.50784 37.1918 8.77994 26.8959 16.105 18.935L27.435 30.265Z'
					fill='currentColor'
				/>
				<text {...rules} textAnchor='middle' fontFamily='Notable' fill='currentColor'>
					{content}
				</text>
			</svg>
		</div>
	)
}
