import cn from 'classnames'
import { useEffect, useState } from 'react'

import c from './InputNumber.module.scss'

interface TNumber {
	value?: number | null
	size?: 'sm' | 'md' | 'lg'
	onChange?(value: number | null): void
	className?: string

	min?: number
	max?: number
}

export function InputNumber({
	value,
	size = 'md',
	onChange,
	className,
	min = 0,
	max = 100,
	...inputProps
}: InputNumberProps) {
	const [inputValue, setInputValue] = useState<string>(value?.toString() ?? '')

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const rawValue = e.target.value

		// Разрешить пустое значение для очистки поля
		if (rawValue === '') {
			setInputValue('')
			onChange?.(null)
			return
		}

		// Ограничить ввод только допустимыми символами (цифры и, возможно, знак минуса)
		if (!/^(-?\d*)$/.test(rawValue)) {
			return
		}

		const numericValue = Number(rawValue)

		// Ограничить значение в пределах min и max
		if (numericValue >= min && numericValue <= max) {
			setInputValue(rawValue)
			onChange?.(numericValue)
		} else {
			setInputValue(rawValue) // Разрешить временное "выходящее" значение
		}
	}

	const handleBlur = () => {
		let numericValue = Number(inputValue)

		if (inputValue === '') {
			setInputValue(min.toString())
			onChange?.(min)
			return
		}

		if (numericValue < min) numericValue = min
		if (numericValue > max) numericValue = max

		setInputValue(numericValue.toString())
		onChange?.(numericValue)
	}

	useEffect(() => {
		if (value === null || value === undefined) setInputValue('')
		else setInputValue(value.toString())
	}, [value])

	return (
		<div className={cn(c['input-number-wrapper'], c[size], className)}>
			<input
				type='text'
				className={cn(c['input-number'], className)}
				onChange={handleChange}
				onBlur={handleBlur}
				value={inputValue}
				{...inputProps}
			/>
		</div>
	)
}

type InputNumberProps = TNumber &
	Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof TNumber | 'onBlur'>
