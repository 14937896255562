import { filesAPI } from '../api/files.api'

import { filesStoreManager } from './files.store'

export async function filesGetCards() {
	const data = await filesAPI.getParent(117)
	filesStoreManager(s => {
		s.cards = [...data]
	})
}
