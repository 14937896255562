import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { privacyOptions } from '@/components/Menu/lib/menu.constants'

import { useGameActions } from '@/entities/game'
import { useUserActions } from '@/entities/user'

import { Button, Icon, Input, RoundedTriangle } from '@/shared/ui'

import c from './PlayWithFriends.module.scss'

export function PlayWithFriends() {
	const { t } = useTranslation()
	const nav = useNavigate()

	const { userAuthRefresh } = useUserActions()
	const { gameCreateWS } = useGameActions()

	const hanldePlayWithFriendsBtn = async () => {
		await userAuthRefresh()
			.catch(() => {
				throw new Error('Error while refreshing auth')
			})
			.then(() => gameCreateWS())
			.catch(() => {
				throw new Error('Error while connecting socket')
			})
			.finally(() => nav('/game'))
	}

	type PrivacyOptions = (typeof privacyOptions)[number]['value']
	const [privacy, setPrivacy] = useState<PrivacyOptions>('public')
	const onChangePrivacy = (value: PrivacyOptions) => setPrivacy(value)

	const privacyOptionsFormated = privacyOptions.map(i => ({
		value: i.value,
		label: (
			<span className={c['privacy-switch__option']}>
				<Icon
					className={c['privacy-switch__option-icon']}
					name={i.value === 'private' ? 'lock' : 'users'}
				/>
				<span>{i.label}</span>
			</span>
		)
	}))

	return (
		<div className={c['play-with-friends']} onClick={hanldePlayWithFriendsBtn}>
			<div className={c['play-with-friends__label']}>
				<span>{t('menu_play-w-friends-label')}</span>
				<span>{t('menu_play-w-friends-desc')}</span>
			</div>
			<Input.Switch
				className={c['privacy-switch']}
				selected={privacy}
				onChange={onChangePrivacy}
				options={privacyOptionsFormated}
			/>
			<Button color='green' size='lg' type='primary-round' className={c['play-with-friends__btn']}>
				<RoundedTriangle className={c['play-with-friends__btn-icon']} />
			</Button>
		</div>
	)
}
