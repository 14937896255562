import classNames from 'classnames'

import { useImageError } from '@/shared/hooks'
import { Button } from '@/shared/ui'

import { TUserInLobby } from '../../model/user.types'

import c from './UserInLobby.module.scss'

export function UserInLobby({ tg_uid, avatar, username, status, isOnline }: TUserInLobby) {
	// TODO: replace with config
	const btnColor = status === 'ready' ? 'gold' : status === 'waiting' ? 'orange' : 'green'
	const [isAvatarErr, onAvatarErr] = useImageError()

	return (
		<div className={c['user-in-lobby']}>
			<div className={c['user-in-lobby__data']}>
				{/* Avatar & Online status*/}
				<div className={classNames(c['user-in-lobby__data--avatar'], isOnline && c['online'])}>
					{isAvatarErr && <span className={c['user-in-lobby__data--avatar-plug']} />}
					{!isAvatarErr && <img src={avatar ?? ''} width={36} height={36} onError={onAvatarErr} />}
				</div>

				{/* Username */}
				<div className={c['user-in-lobby__data--username']}>{username}</div>

				{/* User Id */}
				<div className={c['user-in-lobby__data--id']}>
					<span>ID:&nbsp;</span>
					<span className={c['id']}>{tg_uid}</span>
				</div>
			</div>

			{/* Button with act */}
			<Button
				text={status === 'init' ? 'invite' : status}
				color={btnColor}
				disabled={status !== 'init'}
			/>
		</div>
	)
}
