// import { useTranslation } from 'react-i18next'
import { UserInLobby } from '@/entities/user'

// import { Button, Icon } from '@/shared/ui'
import { useLobbyContent } from '../../hooks/useLobbyContent'

import c from './LobbyContent.module.scss'

export function LobbyContent() {
	// const { t } = useTranslation()
	const {
		users
		// playersMax,
		// playersMin,
		// playersReady,
		// startDisabled,
	} = useLobbyContent()

	return (
		<div className={c['lobby-content']}>
			{users.length &&
				users.map(user => <UserInLobby key={`${user.tg_uid}-${user.username}`} {...user} />)}

			{/* <div className={c['lobby-content__start']}>
				<div className={c['lobby-content__start--label']}>
					<h3>{t('lobby_start-btn-label', { playersReady, playersMax })}</h3>
					<span>{t('lobby_start-btn-desc', { playersMin })}</span>
				</div>
				<Button
					disabled={startDisabled}
					aria-disabled={startDisabled}
					className={c['start-btn']}
					color='orange'
					size='lg'>
					<span>{t('lobby_start-btn-text')}</span>
					<Icon name='rounded-triangle' w={20} className={c['start-btn__icon']} />
				</Button>
			</div> */}
		</div>
	)
}
