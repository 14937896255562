import { useEffect, useRef } from 'react'

import { useChatStore } from '@/components/Chat/model/chat.store'

import { ChatMessage } from '../../ChatMessage/ChatMessage'
import c from '../ChatWindow.module.scss'

export function UsersChat() {
	const msgs = useChatStore(s => s.msgs)
	const messages = Array.from(msgs)

	const scrollAnchor = useRef<HTMLDivElement>(null)
	useEffect(() => {
		scrollAnchor.current?.scrollIntoView({ behavior: 'smooth' })
	}, [msgs])

	return (
		<div className={c['chat-window--users']}>
			{messages.map((m, i) => (
				<ChatMessage key={`${m.type}-${i}`} {...m} />
			))}
			<span className={c['scroll-anchor']} ref={scrollAnchor} />
		</div>
	)
}
