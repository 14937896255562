import { WebSocketManager } from '@/shared/utils'

import { gameDynamicsInit } from './game.init'
import { gameStoreManager } from './game.store'
import type { AutoRebuy, GameWS } from './game.types'

export const gameCreateWS = () => {
	gameStoreManager(s => {
		s.ws = new WebSocketManager('/game/1/')
		s.ws.connect()
	})
}

export const gameRemoveWS = () => {
	gameStoreManager(s => {
		if (s.ws) {
			s.ws.close()
			s.ws = null
			s.playerSeat = null
			s.autoRebuy = { active: false, ammount: 0 }
			s.game = { ...gameDynamicsInit }
		} else console.error(`Game websocket in null`)
	})
}

export const gameChangeState = ({ type, ...data }: GameWS['game_state']) => {
	void type
	gameStoreManager(s => {
		s.game = { ...data }
	})
}

export const gameSetPlayerSeat = (seatIndex: number) => {
	gameStoreManager(s => {
		s.playerSeat = seatIndex
	})
}

export const gameSetAutoRebuy = (data: AutoRebuy) => {
	gameStoreManager(s => {
		s.autoRebuy = { ...data }
	})
}

export const gameSetPremove = (premove: GameWS['action'] | null) => {
	gameStoreManager(s => {
		s.premove = premove ? { ...premove } : null
	})
}
