import { GameSeat, rotateSeats, useGameStore } from '@/entities/game'
import { UserInGame } from '@/entities/user'

export function GameChairs() {
	const {
		game: { chairs },
		playerSeat: seat
	} = useGameStore()

	const seats = seat === null ? rotateSeats(chairs, 4) : rotateSeats(chairs, seat)

	return (
		<>
			{seats.map(({ item, index }, i) =>
				item ? (
					<UserInGame key={`player-seat-${index}`} index={index} seat={i + 1} {...item} />
				) : (
					<GameSeat key={`game-seat-${index}`} index={index} seat={i + 1} />
				)
			)}
		</>
	)
}
