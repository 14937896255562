import { OverlayID, useOverlayActions } from '..'
import { useEffect } from 'react'

export function useCreateOverlay(id: OverlayID) {
	const { overlayRemoveInstance, overlayCreateInstnace } = useOverlayActions()

	useEffect(() => {
		overlayCreateInstnace(id)
		return () => overlayRemoveInstance(id)
	}, [id, overlayCreateInstnace, overlayRemoveInstance])
}
