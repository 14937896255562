import { sendRequest } from '@/shared/utils'

import { FileNode } from '../model/files.types'

import { filesEndpoints } from './files.endpoints'

export class filesAPI {
	static async getFiles() {
		const res = await sendRequest.get<FileNode[]>({
			url: filesEndpoints['files']
		})

		if (res && res.status === 200) return res.data
		else throw new Error(`${this.name} :: Error while gathering data`)
	}

	static async getFile(id: number) {
		const res = await sendRequest.get<FileNode>({
			url: `${filesEndpoints['files']}${id}/`
		})

		if (res && res.status === 200) return res.data
		else throw new Error(`${this.name} :: Error while gathering data`)
	}

	static async getParent(id: number) {
		const res = await sendRequest.get<FileNode[]>({
			url: filesEndpoints['filesByParent'],
			params: { parent_id: id }
		})

		if (res && res.status === 200) return res.data
		else throw new Error(`${this.name} :: Error while gathering data`)
	}
}
