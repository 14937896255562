import { sendRequest } from '@/shared/utils'

import type { GetMe, GetTokens, GetUser, GetUsers } from './user.api.types'
import { userEndpoints } from './user.endpoints'

export class userAPI {
	static async getData() {
		const res = await sendRequest.get({
			url: userEndpoints['getUser']
		})

		if (res && res.status === 200) return res.data
		else throw new Error(`${this.name} :: Error while gathering data`)
	}

	static async getTokens(data: GetTokens['Req']) {
		const res = await sendRequest.post<GetTokens['Res']>({
			url: userEndpoints['getTokens'],
			data,
			withCredentials: false
		})

		if (res && res.status === 200) return res.data
		else throw new Error(`${this.name} :: Error while gathering tokens`)
	}

	static async refreshTokens() {
		const res = await sendRequest
			.post({
				url: userEndpoints['refreshToken']
			})
			.then(r => r.status)
			.catch(e => {
				throw new Error(e)
			})

		return res
	}

	static async getUsers(params: GetUsers['Req']) {
		const res = await sendRequest.get<GetUsers['Res']>({
			url: userEndpoints['getUsers'],
			params
		})

		if (res && res.status === 200) return res.data
		else throw new Error(`${this.name} :: Error while gathering users list`)
	}

	static async getUser({ id }: GetUser['Req']) {
		const res = await sendRequest.get<GetUser['Res']>({
			url: `${userEndpoints['getUser']}${id}/`
		})

		if (res && res.status === 200) return res.data
		else throw new Error(`${this.name} :: Error while gathering user with id ${id}`)
	}

	static async getMe() {
		const res = await sendRequest.get<GetMe['Res']>({
			url: userEndpoints['getMe']
		})

		if (res && res.status === 200) return res.data
		else throw new Error(`${this.name} :: Error while gathering current user data`)
	}
}
