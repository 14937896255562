import type { GameTableSeat } from '../model/game.types'

export function rotateSeats(seats: GameTableSeat[], playerIndex: number) {
	const arrWithIndex = seats.map((item, index) => ({ item, index }))

	const shiftedIndex = (playerIndex + 2) % seats.length
	const firstPart = arrWithIndex.slice(shiftedIndex)
	const secondPart = arrWithIndex.slice(0, shiftedIndex)

	return [...firstPart, ...secondPart]
}

export function calculateSeatPosition(
	index: number,
	totalSeats: number,
	radius: number,
	offsetX: number = 0,
	offsetY: number = 0
) {
	const angle = (2 * Math.PI) / totalSeats

	const currentAngle = (index - 1) * angle - Math.PI / 2

	const x = Math.cos(currentAngle) * (radius + offsetX)
	const y = Math.sin(currentAngle) * (radius + offsetY)

	// Смещение на 50% + радиус, чтобы размещать элементы вокруг центра
	const left = 50 + x
	const top = 50 + y

	return { left: `${left}%`, top: `${top}%` }
}
