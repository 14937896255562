import { useTranslation } from 'react-i18next'

import { PlayingCard, useGameStore } from '@/entities/game'

import c from './GameTable.module.scss'

export function GameTable() {
	const { t } = useTranslation()

	const game = useGameStore(s => s.game)
	const betText = `${Math.floor(game.blind_amount / 2)}/${game.blind_amount}`

	return (
		<div className={c['game-table']}>
			<div className={c['game-table__cards']}>
				{game.table.map(c => (c ? <PlayingCard name={c.name} in_combo={c.in_combo} /> : null))}
			</div>
			<div className={c['game-table__pot']}>
				<span>{t('pot')}</span>
				<span>{game.pot}$</span>
			</div>
			<div className={c['game-table__bet']}>{t('bet-with-money', { betText })}</div>
		</div>
	)
}
