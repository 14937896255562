import { withHeaderLayout } from '@/app/layouts/withHeaderLayout'

import { MenuContent, MenuFooter } from '@/components/Menu'

import c from './MenuPage.module.scss'

function MenuPage() {
	return (
		<div className={c['page-menu']}>
			<MenuContent />
			<MenuFooter />
		</div>
	)
}

const MenuPageWithLayout = withHeaderLayout(MenuPage)
export default MenuPageWithLayout
