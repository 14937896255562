import { useCallback, useRef, useState } from 'react'

export function useTimer(targetTime: string) {
	const [percentRemaining, setPercentRemaining] = useState<number>(100) // 100% в начале
	const timerRef = useRef<NodeJS.Timeout | null>(null) // Хранение ID таймера
	const startTimeRef = useRef<number | null>(null) // Время начала
	const targetTimestamp = new Date(targetTime).getTime() // Целевой таймстемп

	// Функция для обновления процентов
	const updatePercent = useCallback(() => {
		if (!startTimeRef.current) return

		const now = Date.now()
		const totalTime = targetTimestamp - startTimeRef.current
		const remainingTime = targetTimestamp - now

		if (remainingTime <= 0) {
			setPercentRemaining(0)
			stopTimer()
			return
		}

		const percent = (remainingTime / totalTime) * 100
		setPercentRemaining(percent)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetTimestamp])

	// Функция для запуска таймера
	const startTimer = useCallback(() => {
		if (timerRef.current) return // Избегаем дублирующих таймеров
		startTimeRef.current = Date.now() // Записываем время начала

		timerRef.current = setInterval(() => {
			updatePercent()
		}, 100) // Обновление каждую 100 мс
	}, [updatePercent])

	// Функция для остановки таймера
	const stopTimer = useCallback(() => {
		if (timerRef.current) {
			clearInterval(timerRef.current)
			timerRef.current = null
		}
	}, [])

	// Функция для обнуления таймера
	const resetTimer = useCallback(() => {
		stopTimer()
		setPercentRemaining(100)
		startTimeRef.current = null
	}, [stopTimer])

	return {
		percentRemaining,
		startTimer,
		stopTimer,
		resetTimer
	}
}
