import cn from 'classnames'
import { createPortal } from 'react-dom'

import { OverlayProps } from '../model/overlay.types'

import c from './Overlay.module.scss'

export function Overlay({
	id = 'overlay-key',
	transparent = false,
	smooth = false,
	className,
	children
}: OverlayProps) {
	const classNames = cn(
		c['global-overlay'],
		transparent && c['transparent'],
		smooth && c['smooth'],
		className
	)

	return createPortal(<div className={classNames}>{children}</div>, document.body, id)
}
