import { ChatWS, useChatActions } from '..'
import { useState } from 'react'

import { useGameStore } from '@/entities/game'

import { useChatStore } from '../model/chat.store'

export function useChatControls() {
	const ws = useGameStore(s => s.ws)
	if (!ws) throw new Error('Game websocket is null')

	const selectedChat = useChatStore(s => s.selectedChat)
	const { chatSelectChat } = useChatActions()
	const onToggleChat = (v: boolean) => chatSelectChat(v ? 'users' : 'dealer')

	const [message, setMessage] = useState<string>('')
	const onChange = (value: string) => setMessage(value)
	const send = async () => {
		if (!message) return
		await ws.send<ChatWS['send']>({ type: 'chat_message', message })
		setMessage('')
	}

	const disabledConrols = {
		disabled: selectedChat === 'dealer',
		'aria-disabled': selectedChat === 'dealer'
	}

	const btnProps = {
		color: 'gold',
		onClick: send,
		...disabledConrols
	} as const

	return { onToggleChat, onChange, message, btnProps, selectedChat, disabledConrols }
}
