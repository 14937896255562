import cn from 'classnames'
import { useEffect, useState } from 'react'

import c from './InputToggle.module.scss'

interface TToggle {
	labels?: {
		on: string
		off: string
	}

	checked?: boolean
	onCheck?(value: boolean): void

	className?: string
	disabled?: boolean
}

export function InputToggle({
	labels,
	checked = false,
	onCheck,
	className,
	disabled = false,
	...inputProps
}: InputToggleProps) {
	const [isChecked, setChecked] = useState<boolean>(checked)

	useEffect(() => {
		setChecked(checked)
	}, [checked])

	const toggleCheck = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault()
		e.stopPropagation()
		const newChecked = !isChecked

		setChecked(newChecked)
		if (onCheck) onCheck(newChecked)
	}

	return (
		<div
			className={cn(c['input-toggle'], disabled && c['disabled'], className)}
			onClick={toggleCheck}>
			<input
				type='checkbox'
				defaultChecked={isChecked}
				aria-checked={isChecked}
				hidden
				{...inputProps}
			/>
			<div className={cn(c['input-toggle__slider'], isChecked ? c.on : c.off)} role='slider'>
				{labels && (
					<div className={c['input-toggle__labels']}>
						<span>{labels.off}</span>
						<span>{labels.on}</span>
					</div>
				)}
			</div>
		</div>
	)
}

type InputToggleProps = TToggle & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof TToggle>
