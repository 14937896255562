import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useOverlay } from '@/components/Overlay'

import { Button, Icon, Input } from '@/shared/ui'

import { useSupportActions, useSupportStore } from '../..'
import { supportSwitchOptions } from '../../lib/support.constants'
import { SupportPage } from '../../model/support.types'

import c from './SupportHeader.module.scss'

export function SupportHeader() {
	const { t } = useTranslation()
	const { supportChangePage } = useSupportActions()

	const selectedPage = useSupportStore(s => s.selectedPage)
	const supportSwitchOptionsFromated = supportSwitchOptions.map(i => ({
		value: i.value,
		label: <span className={c['switch-page__option']}>{t(i.label)}</span>
	}))

	const onSelectPage = (value: SupportPage) => supportChangePage(value)

	const [, toggleSupportOverlay] = useOverlay('support-overlay')
	const nav = useNavigate()
	const onBack = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		e.preventDefault()
		if (window.location.href.includes('/support')) nav('/menu')
		else toggleSupportOverlay(false)
	}

	return (
		<div className={c['support-header']}>
			<Button onClick={onBack} type='ghost' size='sm'>
				<Icon name='arrow-back' w={26} />
			</Button>

			<Input.Switch
				options={supportSwitchOptionsFromated}
				selected={selectedPage}
				onChange={onSelectPage}
				className={c['switch-page']}
				color='orange'
			/>
		</div>
	)
}
