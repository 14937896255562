import { useTranslation } from 'react-i18next'

import { Button, Icon, Input } from '@/shared/ui'

import { useChatControls } from '../../hooks/useChatControls'

import c from './ChatControls.module.scss'

export function ChatControls() {
	const { t } = useTranslation()
	const { btnProps, onChange, onToggleChat, selectedChat, message, disabledConrols } =
		useChatControls()

	return (
		<div className={c['chat-controls']}>
			<Input.Toggle
				labels={{ on: t('chat_toggle-chat-user'), off: t('chat_toggle-chat-dealer') }}
				onCheck={onToggleChat}
				checked={selectedChat === 'users'}
			/>
			<Input value={message} onChange={onChange} {...disabledConrols} />
			{window.screen.width <= 360 ? (
				<Button type='primary-round' size='sm' className={c['send-btn']} {...btnProps}>
					<Icon name='chevron-right' />
				</Button>
			) : (
				<Button text={t('chat_send-btn-label')} {...btnProps} />
			)}
		</div>
	)
}
