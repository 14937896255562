import cn from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Input } from '@/shared/ui'
import { formatNumber } from '@/shared/utils'

import { useGameControls } from '../../hooks/useGameControls'

import c from './GameControls.module.scss'

export const GameControls = memo(function GameControls() {
	const { t } = useTranslation()
	const {
		onFold,
		onCheck,
		onCall,
		handleRaise,
		onSetHalfPot,
		onSetPot,
		onAllIn,
		onChangeRaise,
		raise,
		limits,
		availableActions,
		isCurrentTurn,
		premoveAction
	} = useGameControls()

	const raiseText = raise ? ` - ${formatNumber(raise)}$` : ''

	return (
		<div className={c['game-controls']}>
			<div className={cn(c['game-controls__raise'], raise && c['--visible'])}>
				<Button size='sm' color='yellow' text={t('game_action-1/2-pot')} onClick={onSetHalfPot} />
				<Button size='sm' color='yellow' text={t('game_action-pot')} onClick={onSetPot} />
				<Button size='sm' color='yellow' text={t('game_action-all-in')} onClick={onAllIn} />
				<Input.Range
					className={c['game-controls__raise-range']}
					max={limits.max}
					onChange={onChangeRaise}
					value={raise}
					min={limits.min}
				/>
			</div>

			<div className={c['game-controls__action']}>
				<Button
					color={isCurrentTurn ? 'green' : 'white'}
					text={t('game_action-fold')}
					disabled={!availableActions.includes('fold')}
					aria-disabled={!availableActions.includes('fold')}
					onClick={onFold}
					selected={isCurrentTurn ? undefined : premoveAction === 'fold'}
				/>
				<Button
					color={isCurrentTurn ? 'green' : 'white'}
					text={t('game_action-check')}
					disabled={!availableActions.includes('check')}
					aria-disabled={!availableActions.includes('check')}
					onClick={onCheck}
					selected={isCurrentTurn ? undefined : premoveAction === 'check'}
				/>
				<Button
					color={isCurrentTurn ? 'green' : 'white'}
					text={t('game_action-call')}
					aria-disabled={!availableActions.includes('call')}
					disabled={!availableActions.includes('call')}
					onClick={onCall}
					selected={isCurrentTurn ? undefined : premoveAction === 'call'}
				/>
				<Button
					color={isCurrentTurn ? 'green' : 'white'}
					text={t('game_action-raise', { raise: raiseText })}
					aria-disabled={!availableActions.includes('raise')}
					disabled={!availableActions.includes('raise')}
					onClick={handleRaise}
					selected={isCurrentTurn ? undefined : premoveAction === 'raise'}
				/>
			</div>
		</div>
	)
})
