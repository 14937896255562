import { create } from 'zustand'

import { createStoreManager } from '@/shared/utils'

import { TFriendsLobby } from './lobby.types'

export const useLobbyStore = create<TFriendsLobby>(() => ({
	users: []
}))

export const lobbyStoreManager = createStoreManager(useLobbyStore)
