export function formatNumber(value: number | `${number}`, threshold: number = 3): string {
	const val = Number(value)
	const numberLength = Math.floor(Math.log10(val)) + 1

	// Если длина числа меньше или равна threshold, просто возвращаем число с разделителями
	if (numberLength <= threshold) {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') // Пример: 5000 -> "5 000"
	}

	// Если длина больше threshold, используем логарифмическое форматирование
	const suffix = 'k'.repeat(Math.floor(Math.log10(val) / 3))
	const formattedValue = (val / Math.pow(1000, suffix.length)).toFixed(2).replace(/\.00$/, '')

	return `${formattedValue}${suffix}`
}
