import { playersLimit } from '../lib/lobby.constants'
import { useLobbyStore } from '../model/lobby.store'

export function useLobbyContent() {
	const { min: playersMin, max: playersMax } = playersLimit
	const { users } = useLobbyStore()

	// TODO: replace this with something more efficient
	const playersReady = users.reduce((acc, p) => {
		if (p.status === 'ready') acc++
		return acc
	}, 1)

	const startDisabled = playersReady < playersLimit.min

	return {
		users,
		startDisabled,
		playersReady,
		playersMin,
		playersMax
	}
}
