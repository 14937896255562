import { useOverlayActions } from '..'

import { useOverlayStore } from '../model/overlay.store'
import { OverlayID } from '../model/overlay.types'

export function useOverlay(id: OverlayID) {
	const overlays = useOverlayStore(s => s.overlays)
	const { overlayToggleVisibility } = useOverlayActions()

	const isOverlayVisible = overlays.get(id) as boolean

	const onToggleOverlay = (isVisible?: boolean) => {
		overlayToggleVisibility(id, isVisible)
	}

	return [isOverlayVisible, onToggleOverlay] as const
}
