import cN from 'classnames'
import { useEffect, useRef, useState } from 'react'

import c from './InputSwitch.module.scss'

interface InputSwitchProps {
	options: SwitchOption[] | readonly SwitchOption[]
	selected?: SwitchOption['value']
	onChange?(key: SwitchOption['value']): void
	color?: 'gold' | 'green' | 'orange' | 'white' | 'yellow' | 'red'
	className?: string
}

export function InputSwitch({
	options,
	selected,
	onChange,
	className,
	color = 'green'
}: InputSwitchProps) {
	const [selectedOption, setSelectedOption] = useState<SwitchOption['value']>(
		selected ?? options[0]?.value
	)

	const [activeStyle, setActiveStyle] = useState<{ width: number; left: number }>({
		width: 0,
		left: 0
	})

	const containerRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		const container = containerRef.current
		if (!container) return

		const activeElement = container.querySelector('[aria-selected="true"]') as HTMLDivElement
		if (activeElement) {
			setActiveStyle({
				width: activeElement.offsetWidth,
				left: activeElement.offsetLeft
			})
		}
	}, [selectedOption])

	const onSelectOption = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		value: SwitchOption['value']
	) => {
		e.preventDefault()
		e.stopPropagation()

		setSelectedOption(value)

		if (onChange) onChange(value)
	}

	return (
		<div className={cN(c['input-switch'], className)} ref={containerRef}>
			<div className={cN(c['input-switch__active'], c[color])} style={activeStyle} />
			{options.map(({ value, label }) => (
				<div
					aria-selected={selectedOption === value}
					className={c['input-switch__option']}
					onClick={e => onSelectOption(e, value)}
					data-key={value}
					key={value}>
					{label}
				</div>
			))}
		</div>
	)
}

type SwitchOption = {
	value: string | number
	label: React.ReactNode
}
