import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import { useSupportStore } from '../../model/support.store'

import c from './SupportContent.module.scss'

export function SupportContent() {
	const { t } = useTranslation()
	const selectedPage = useSupportStore(s => s.selectedPage)

	return (
		<>
			{selectedPage === 'rules' && (
				<div className={c['support-content']}>
					<ReactMarkdown>{t('support_rules')}</ReactMarkdown>
				</div>
			)}

			{selectedPage === 'ranking' && (
				<div>
					<span>Ranking</span>
				</div>
			)}
		</>
	)
}
