import { useTranslation } from 'react-i18next'

import { useImageError } from '@/shared/hooks'
import { Icon } from '@/shared/ui'
import { formatNumber } from '@/shared/utils'

import { useUserStore } from '../../model/user.store'

import c from './UserData.module.scss'

export function UserData({ direction }: { direction: 'horizontal' | 'vertical' }) {
	const { t } = useTranslation()
	const { avatar, balance, tg_uid, tg_username } = useUserStore()
	const prettyBalance = formatNumber(balance, 4)
	const [isAvatarErr, onAvatarErr] = useImageError()

	const vertical = (
		<div className={c['user-data']} data-direction='vertical'>
			<div className={c['user-data__data']}>
				<div className={c['user-data__data-avatar']}>
					{isAvatarErr && <span className={c['user-data__data-avatar-plug']} />}
					{!isAvatarErr && <img src={avatar ?? ''} width={36} height={36} onError={onAvatarErr} />}
				</div>
				<div className={c['user-data__data-name']}>{tg_username}</div>
				<div className={c['user-data__data-id']}>
					<span>{t('id')}:&nbsp;</span>
					<span className={c['id']}>{tg_uid}</span>
					<Icon name='copy' w={13} h={16} />
				</div>

				<div className={c['user-data__balance']}>
					<span>{t('balance')}</span>
					<div className={c['balance']}>
						<b>{prettyBalance}</b>
						<span>&nbsp;$</span>
					</div>
				</div>
			</div>
		</div>
	)

	const hotizontal = (
		<div className={c['user-data']} data-direction='horizontal'>
			<div className={c['user-data__data']}>
				<div className={c['user-data__data-avatar']}>
					{isAvatarErr && <span className={c['user-data__data-avatar-plug']} />}
					{!isAvatarErr && <img src={avatar ?? ''} width={36} height={36} onError={onAvatarErr} />}
				</div>
				<div className={c['user-data__data-name']}>{tg_username}</div>
				<div className={c['user-data__data-id']}>
					<span>{t('id')}:&nbsp;</span>
					<span className={c['id']}>{tg_uid}</span>
				</div>
			</div>
			<div className={c['user-data__balance']}>
				<span>{t('balance')}</span>
				<div className={c['balance']}>
					<b>{prettyBalance}</b>
					<span>&nbsp;$</span>
				</div>
			</div>
		</div>
	)

	return direction === 'vertical' ? vertical : hotizontal
}
