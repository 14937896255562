import { RouterProvider } from 'react-router-dom'

import { routes } from '../configs/routes'

interface RoutesProviderProps {
	children?: React.ReactNode
}

export function RoutesProvider({ children }: RoutesProviderProps) {
	return (
		<>
			<RouterProvider router={routes} />
			{children}
		</>
	)
}
