import classNames from 'classnames'

import c from './Icon.module.scss'

interface IconProps extends Omit<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'className'> {
	name: string
	w?: number
	h?: number
	className?: string
}

export function Icon({ name, className = '', w = 18, h, ...svgProps }: IconProps) {
	return (
		<svg
			className={classNames(c.icon, className)}
			width={w}
			height={h ?? w}
			id={name}
			{...svgProps}>
			<use href={`#icon-${name}`} />
		</svg>
	)
}
