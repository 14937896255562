import { useState } from 'react'

import { useOverlay } from '@/components/Overlay'

import { type GameWS, useGameActions, useGameStore } from '@/entities/game'

import { buyInConf } from '../lib/buyIn.constants'
import { getSelectedBuyInAddW } from '../lib/buyIn.helpers'

export function useBuyInContent() {
	const { ws } = useGameStore()
	if (!ws) throw new Error('BuyInContent :: Game Websocket is null')

	const { gameSetAutoRebuy } = useGameActions()
	const [, toggleBuyInOverlay] = useOverlay('buy-in-overlay')

	// TODO: replace with data from backend
	const { init, limits, step } = buyInConf
	const buyInW = `calc(5.375rem + ${getSelectedBuyInAddW(limits.max)}rem)`

	const [buyInAmmount, setBuyInAmmount] = useState<number | null>(init)
	const [isAutoRebuy, setAutoRebuy] = useState<boolean>(false)
	const toggleAutoRebuy = (val: boolean) => setAutoRebuy(val)

	const onChangeBuyInAmmount = (value: number | null) => setBuyInAmmount(value)
	const setMinBuyInAmmount = () => setBuyInAmmount(limits.min)
	const setMaxBuyInAmmount = () => setBuyInAmmount(limits.max)

	const onBuyIn = async () => {
		// TODO: check if balance is enough to re-buy
		if (isAutoRebuy) gameSetAutoRebuy({ active: true, ammount: buyInAmmount ?? limits.min })
		await ws.send<GameWS['ready']>({ type: 'ready', buy_in: buyInAmmount ?? limits.min })
		toggleBuyInOverlay(false)
	}

	return {
		onBuyIn,
		limits,
		step,
		buyInAmmount,
		onChangeBuyInAmmount,
		setMaxBuyInAmmount,
		setMinBuyInAmmount,
		buyInW,
		isAutoRebuy,
		toggleAutoRebuy
	}
}
