import { withLayout } from '@/app/layouts/withLayout'

import { PlayingCard } from '@/entities/game'

import { Chip } from '@/shared/ui'

import c from './DevPage.module.scss'

export function DevPage() {
	return (
		<div className={c['page-game']}>
			<Chip color='var(--color-gold-2)' content={1} />
			<Chip w='1.5625rem' content='D' />

			<PlayingCard name='10C' in_combo={false} />
			<PlayingCard name='10C' in_combo={true} />
		</div>
	)
}

const GamePageWithLayout = withLayout(DevPage)
export default GamePageWithLayout
