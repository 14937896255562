import { withHeaderLayout } from '@/app/layouts/withHeaderLayout'

import { LobbyContent, LobbyHeader } from '@/components/Lobby'

import c from './LobbyPage.module.scss'

function LobbyPage() {
	return (
		<div className={c['page-lobby']}>
			<LobbyHeader />
			<LobbyContent />
		</div>
	)
}

const LobbyPageWithHeader = withHeaderLayout(LobbyPage)
export default LobbyPageWithHeader
