import { overlayStoreManager } from './overlay.store'
import type { OverlayID } from './overlay.types'

export function overlayCreateInstnace(id: OverlayID, isDefaultVisible = false) {
	overlayStoreManager(s => {
		if (s.overlays.has(id)) throw new Error(`Overlay with id - ${id} already exists`)
		s.overlays.set(id, isDefaultVisible)
	})
}

export function overlayRemoveInstance(id: OverlayID) {
	overlayStoreManager(s => {
		if (!s.overlays.has(id)) throw new Error(`Overlay with id - ${id} not found`)
		s.overlays.delete(id)
	})
}

export function overlayToggleVisibility(id: OverlayID, isVisible?: boolean) {
	overlayStoreManager(s => {
		if (!s.overlays.has(id)) throw new Error(`Overlay with id - ${id} not found`)
		const prevState = s.overlays.get(id) as boolean
		s.overlays.set(id, isVisible ?? !prevState)
	})
}
