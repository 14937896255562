import { BuyIn } from '@/components/BuyIn'
import { Overlay } from '@/components/Overlay'

import { GameTable } from '@/entities/game'

import { useGameContent } from '../../hooks/useGameContent'

import c from './GameContent.module.scss'

import { GameChairs } from './GameChairs'

export function GameContent() {
	const { isOverlayVisible } = useGameContent()

	return (
		<div className={c['game-content']}>
			<GameTable />
			<GameChairs />
			{isOverlayVisible && <Overlay children={<BuyIn />} />}
		</div>
	)
}
