import { Icon } from '@/shared/ui/Icon/Icon'

import { useLoadingPage } from '../hooks/useLoadingPage'

import c from './LoadingPage.module.scss'

export function LoadingPage() {
	const { logo } = useLoadingPage()
	return (
		<div className={c['page-loading']}>
			<div className={c['page-loading--container']}>
				<Icon name={logo} className={c['page-loading__logo']} />
				<h1 className={c['page-loading__heading']}>
					<span>Poker</span>
					<span>Rush</span>
				</h1>
			</div>
		</div>
	)
}
