import { formatNumber } from '@/shared/utils'
import { capitalize } from '@/shared/utils/capitalize'

import type { UserInGame } from '../model/user.types'

export const getUserTitle = (user: Pick<UserInGame, 'last_action' | 'username' | 'winner'>) => {
	if (user.winner) return null
	else if (user.last_action) return capitalize(user.last_action)
	else return user.username
}

export const getUserSubtitle = (user: Pick<UserInGame, 'ready' | 'player_balance' | 'winner'>) => {
	if (user.winner) return `+${formatNumber(user.winner)}$`
	else if (user.ready) return `${user.player_balance}$`
	else return null
}
