import { useTranslation } from 'react-i18next'

import { Button, RoundedTriangle } from '@/shared/ui'

import c from './PlayRandom.module.scss'

export function PlayRandom() {
	const { t } = useTranslation()

	return (
		<div className={c['play-random']}>
			<div className={c['play-random__label']}>
				<span>{t('menu_random-table-label')}</span>
				<span>{t('menu_random-table-desc')}</span>
			</div>
			<Button color='orange' type='primary-round' className={c['play-random__btn']}>
				<RoundedTriangle w={40} h={40} className={c['play-random__btn-icon']} />
			</Button>
		</div>
	)
}
