import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { useOverlay } from '@/components/Overlay'

import { Button, Icon } from '@/shared/ui'

import c from './BuyInHeader.module.scss'

export function BuyInHeader() {
	const { t } = useTranslation()
	// ахуеть не знал что можно таким способом "не брать" велью из хука
	const [, toggleOverlay] = useOverlay('buy-in-overlay')
	const onBack = () => toggleOverlay(false)

	return (
		<div className={c['buy-in-header']}>
			<div className={classNames(c['buy-in-header--row'], c.title)}>
				<Button onClick={onBack} type='ghost' size='sm'>
					<Icon name='arrow-back' w={26} />
				</Button>
				<h3>{t('buy-in_header-label')}</h3>
				<div className={c['buy-in-header__plug']} />
			</div>

			<div className={classNames(c['buy-in-header--row'], c.subtitle)}>
				<span>{t('buy-in_header-sublabel')}</span>
			</div>
		</div>
	)
}
