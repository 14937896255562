import { withHeaderLayout } from '@/app/layouts/withHeaderLayout'

import { SupportContent } from '../SupportContent/SupportContent'
import { SupportHeader } from '../SupportHeader/SupportHeader'

import c from './SupportPage.module.scss'

export function SupportPage() {
	return (
		<div className={c['page-support']}>
			<SupportHeader />
			<SupportContent />
		</div>
	)
}

const SupportWithHeaderLayout = withHeaderLayout(SupportPage)
export default SupportWithHeaderLayout
