import { useGameStore } from '../model/game.store'

export function useGameUser() {
	const {
		playerSeat,
		game: { chairs }
	} = useGameStore()

	if (playerSeat === null) return null
	else return chairs[playerSeat - 1]
}
