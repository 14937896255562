import cn from 'classnames'
import { useEffect } from 'react'

import { useTimer } from '@/entities/game'

import { useImageError } from '@/shared/hooks'

import c from './UserInGame.module.scss'

export function UserInGameAvatar({ turn }: { turn: { endAt: string; value: boolean } }) {
	const [isAvatarErr, onAvatarErr] = useImageError()
	const { percentRemaining, startTimer, stopTimer, resetTimer } = useTimer(turn.endAt)

	useEffect(() => {
		if (turn.value) {
			startTimer()
		} else {
			stopTimer()
			resetTimer()
		}
	}, [startTimer, stopTimer, resetTimer, turn.value])

	useEffect(() => {
		if (percentRemaining === 0) {
			resetTimer()
		} else return
	}, [percentRemaining, resetTimer])

	return (
		<div className={c['user-in-game__data--avatar']}>
			{isAvatarErr && <span className={c['user-in-game__data--avatar-plug']} />}
			{!isAvatarErr && <img src={'null'} width={36} height={36} onError={onAvatarErr} />}
			<div
				className={cn(c['user-in-game__turn-spin'], turn.value && c['--current-turn'])}
				// @ts-expect-error no error
				style={{ '--progress': `${(100 - percentRemaining) * 3.6}deg` }}
			/>
		</div>
	)
}
