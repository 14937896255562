import { Header } from '@/components/Header'

export function withHeaderLayout<T extends object>(Component: React.ComponentType<T>) {
	return function WithLayoutHeader(props: T) {
		return (
			<div className='layout layout--with-header'>
				<Header />
				<Component {...props} />
			</div>
		)
	}
}
