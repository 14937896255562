interface RoundedTriangleProps {
	className: string
	w?: number
	h?: number
}

export function RoundedTriangle({ className, w = 50, h }: RoundedTriangleProps) {
	return (
		<svg
			fill='white'
			width={w}
			height={h ?? w}
			filter='url(#outer-shadow)'
			className={className}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 55 73'>
			<path d='M51.1561 29.1724C56.0174 32.7845 56.0174 40.063 51.1561 43.6751L15.4006 70.2422C9.44016 74.671 0.978809 70.4166 0.97881 62.9909L0.978812 9.85654C0.978812 2.43083 9.44016 -1.82354 15.4006 2.60523L51.1561 29.1724Z' />

			<defs>
				<filter
					id='inner-shadow'
					x='0.978806'
					y='0.806335'
					width='53.8232'
					height='74.2348'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset />
					<feGaussianBlur stdDeviation='1.5' />
					<feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0.170898 0 0 0 0 0.170898 0 0 0 0 0.170898 0 0 0 1 0'
					/>
					<feBlend mode='normal' in2='shape' result='effect1_innerShadow_1_7' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='2' />
					<feGaussianBlur stdDeviation='1.5' />
					<feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0.215625 0 0 0 0 0.215625 0 0 0 0 0.215625 0 0 0 0.37 0'
					/>
					<feBlend mode='normal' in2='effect1_innerShadow_1_7' result='effect2_innerShadow_1_7' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='5' />
					<feGaussianBlur stdDeviation='1.5' />
					<feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0.215625 0 0 0 0 0.215625 0 0 0 0 0.215625 0 0 0 0.15 0'
					/>
					<feBlend mode='normal' in2='effect2_innerShadow_1_7' result='effect3_innerShadow_1_7' />
				</filter>

				<filter
					id='outer-shadow'
					x='-0.802392'
					y='-0.974873'
					width='58.0925'
					height='75.5663'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dx='2.48808' dy='3.11011' />
					<feGaussianBlur stdDeviation='1.27514' />
					<feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
					<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
					<feBlend mode='normal' in2='shape' result='effect1_innerShadow_1_4' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dx='0.622021' dy='0.622021' />
					<feGaussianBlur stdDeviation='0.311011' />
					<feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
					<feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.56 0' />
					<feBlend mode='normal' in2='effect1_innerShadow_1_4' result='effect2_innerShadow_1_4' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dx='-1.78121' dy='-1.78121' />
					<feGaussianBlur stdDeviation='1.42497' />
					<feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
					<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.39 0' />
					<feBlend mode='normal' in2='effect2_innerShadow_1_4' result='effect3_innerShadow_1_4' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dx='-0.622021' dy='-0.622021' />
					<feGaussianBlur stdDeviation='0.466516' />
					<feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
					<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.53 0' />
					<feBlend mode='normal' in2='effect3_innerShadow_1_4' result='effect4_innerShadow_1_4' />
				</filter>

				<linearGradient
					id='golden'
					x1='75.3185'
					y1='-34.0438'
					x2='-57.7873'
					y2='40.1733'
					gradientUnits='userSpaceOnUse'>
					<stop offset='0.0396694' stopColor='#D28B0F' />
					<stop offset='0.16214' stopColor='#F7BF3A' />
					<stop offset='0.31' stopColor='#FFD90F' />
					<stop offset='0.601525' stopColor='#E9CF7B' />
					<stop offset='0.754498' stopColor='#ECAB05' />
					<stop offset='0.865' stopColor='#FFD22D' />
					<stop offset='1' stopColor='#FFC700' />
				</linearGradient>

				<linearGradient
					id='white'
					x1='75.3185'
					y1='-34.0438'
					x2='-57.7873'
					y2='40.1733'
					gradientUnits='userSpaceOnUse'>
					<stop offset='0.0396694' stopColor='#ffffff' />
					<stop offset='1' stopColor='#ffffff' />
				</linearGradient>
			</defs>
		</svg>
	)
}
