import { useTranslation } from 'react-i18next'

import { GameControls, ShowMuckControls, useGameUser } from '@/entities/game'

import c from './GameFooter.module.scss'

export function GameFooter() {
	const { t } = useTranslation()
	const player = useGameUser()

	return (
		<div className={c['game-footer']}>
			{player ? (
				player.winner ? (
					<ShowMuckControls />
				) : (
					<GameControls />
				)
			) : (
				<span className={c['game-footer__tip']}>{t('game_msg-take-seat')}</span>
			)}
		</div>
	)
}
