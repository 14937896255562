import { createBrowserRouter } from 'react-router-dom'

import { DevPage } from '@/pages/Dev'
import { GamePage } from '@/pages/Game'
import { LoadingPage } from '@/pages/Loading'
import { LobbyPage } from '@/pages/Lobby'
import { MenuPage } from '@/pages/Menu'

import { SupportPage } from '@/components/Support'

import { ErrorBoundary } from '@/shared/ui'

export const routes = createBrowserRouter([
	{ path: '/', element: <LoadingPage />, errorElement: <ErrorBoundary /> },
	{ path: '/menu', element: <MenuPage />, errorElement: <ErrorBoundary /> },
	{ path: '/lobby', element: <LobbyPage />, errorElement: <ErrorBoundary /> },
	{ path: '/game', element: <GamePage />, errorElement: <ErrorBoundary /> },
	{ path: '/support', element: <SupportPage />, errorElement: <ErrorBoundary /> },
	{ path: '/dev', element: <DevPage />, errorElement: <ErrorBoundary /> }
])
