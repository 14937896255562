import cn from 'classnames'

import type { ChatMessageLog, ChatMessageUser, TChatMessage } from '../../model/chat.types'

import c from './ChatMessage.module.scss'

export function ChatMessage(props: TChatMessage) {
	return (
		<div className={cn(c['chat-message'], c[props.type])}>
			{props.type === 'chat_message' && <ChatUserMessage {...props} />}
			{props.type === 'chat_log' && <ChatLogMessage {...props} />}
		</div>
	)
}

function ChatUserMessage(props: ChatMessageUser) {
	const { message, username } = props

	return (
		<>
			<span className={c['chat-message__usr']}>{username}:</span> {message}
		</>
	)
}

function ChatLogMessage(props: ChatMessageLog) {
	const { message } = props
	const time = new Date()
	const logTime = `[${time.getHours()}:${time.getMinutes()}]`

	return (
		<>
			<span className={c['chat-message__time']}>{logTime}</span> {message}
		</>
	)
}
