import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { API_BASE_URL } from '@/app/configs/envs'

class axiosAPI {
	private axios: AxiosInstance

	constructor() {
		if (!API_BASE_URL) throw new Error('API_BASE_URL is not defined')

		this.axios = axios.create({
			baseURL: API_BASE_URL,
			withCredentials: true
		})
	}

	get<T = unknown>({ url, ...options }: RequestParamsGet): Response<T> {
		return this.axios.get<T>(url, options)
	}

	post<T = unknown>({ url, ...options }: RequestParams): Response<T> {
		const { data, ...conf } = options
		return this.axios.post<T>(url, data, conf)
	}

	put<T = unknown>({ url, ...options }: RequestParams): Response<T> {
		const { data, ...conf } = options
		return this.axios.post<T>(url, data, conf)
	}

	patch<T = unknown>({ url, ...options }: RequestParams): Response<T> {
		const { data, ...conf } = options
		return this.axios.post<T>(url, data, conf)
	}

	delete<T = unknown>({ url, ...options }: RequestParams): Response<T> {
		const { data, ...conf } = options
		return this.axios.post<T>(url, data, conf)
	}
}

type RequestParams = Omit<AxiosRequestConfig, 'baseURL' | 'method'> & { url: string }
type RequestParamsGet = Omit<RequestParams, 'data'>
type Response<T> = Promise<AxiosResponse<T>>

export const sendRequest = new axiosAPI()
