import cn from 'classnames'
import { useEffect, useState } from 'react'

import c from './Input.module.scss'

import { InputNumber } from './InputNumber/InputNumber'
import { InputRadio } from './InputRadio/InputRadio'
import { InputRange } from './InputRange/InputRange'
import { InputSwitch } from './InputSwitch/InputSwitch'
import { InputToggle } from './InputToggle/InputToggle'

interface TInput {
	value?: string
	size?: 'sm' | 'md' | 'lg'
	onChange?(value: string): void
	className?: string

	maxLength?: number
}

export function Input({
	onChange,
	value,
	className,
	size = 'md',
	maxLength = 250,
	...inputProps
}: InputSwitchProps) {
	const [inputValue, setInputValue] = useState<string>(value ?? '')

	useEffect(() => {
		setInputValue(value ?? '')
	}, [value])

	const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > maxLength) return
		setInputValue(e.target.value)
		if (onChange) onChange(e.target.value)
	}

	return (
		<div className={cn(c['input-wrapper'], c[size], className)}>
			<input className={c['input']} value={inputValue} onChange={onInput} {...inputProps} />
			<span className={c['input-limits']}>{`${inputValue.length}/${maxLength}`}</span>
		</div>
	)
}

Input.Range = InputRange
Input.Radio = InputRadio
Input.Toggle = InputToggle
Input.Switch = InputSwitch
Input.Number = InputNumber

type InputSwitchProps = TInput & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof TInput>
