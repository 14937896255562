import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/shared/ui'

import c from './MenuFooter.module.scss'

export function MenuFooter() {
	const { t } = useTranslation()

	const nav = useNavigate()
	const onNavRules = () => nav('/support')

	const navDev = () => nav('/dev')

	return (
		<div className={c['menu-footer']}>
			<Button text={t('faq')} onClick={navDev} />
			<Button text={t('support')} />
			<Button text={t('rules')} onClick={onNavRules} />
		</div>
	)
}
