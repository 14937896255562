export const buyInConf = {
	limits: { min: 100, max: 1000 },
	init: 0,
	step: 0
}

buyInConf.init = buyInConf.limits.min + (buyInConf.limits.max - buyInConf.limits.min) / 2
buyInConf.step = Math.round(buyInConf.limits.max / 100) * 10

export const betText = '100/200'
