import cn from 'classnames'
import { useEffect } from 'react'

import { useGameStore } from '@/entities/game'

import { useChatActions } from '../..'
import type { ChatMessageLog, ChatMessageUser, ErrorMessage } from '../..'
import { useChatStore } from '../../model/chat.store'

import c from './ChatWindow.module.scss'

import { DealerChat } from './Chats/DealerChat'
import { UsersChat } from './Chats/UsersChat'

export function ChatWindow() {
	const ws = useGameStore(s => s.ws)
	if (!ws) throw new Error('Game WebSocket is null')

	const { chatAddMessage } = useChatActions()

	useEffect(() => {
		ws.on<ChatMessageUser>('chat_message', chatAddMessage)
		ws.on<ChatMessageLog>('chat_log', chatAddMessage)
		ws.on<ErrorMessage>('error', chatAddMessage)
	}, [ws, chatAddMessage])

	const selectedChat = useChatStore(s => s.selectedChat)

	return (
		<div className={cn(c['chat-window'], c[selectedChat])}>
			<UsersChat />
			<DealerChat />
		</div>
	)
}
