import cN from 'classnames'

import { Button, Icon } from '@/shared/ui'

import c from './UserCard.module.scss'

interface UserCard {
	children?: React.ReactNode
	className?: string
	onClose?(): void
}

export function UserCard({ children, className, onClose, ...props }: UserCardProps) {
	const onCloseCard = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		e.preventDefault()
		if (onClose) onClose()
	}

	return (
		<div className={cN(c['user-card'], className)} {...props}>
			{onClose && (
				<Button
					className={c['user-card__close']}
					onClick={onCloseCard}
					type='primary-round'
					size='sm'
					color='green'>
					<Icon name='close' w={25} />
				</Button>
			)}
			{children}
		</div>
	)
}

type UserCardProps = UserCard &
	Omit<
		React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
		keyof UserCard
	>
