import { useEffect } from 'react'

import { withLayout } from '@/app/layouts/withLayout'

import { Chat } from '@/components/Chat'
import { GameContent, GameFooter, GameHeader } from '@/components/Game'

import { useGameActions } from '@/entities/game'

import c from './GamePage.module.scss'

export function GamePage() {
	const { gameRemoveWS } = useGameActions()

	useEffect(() => {
		return () => gameRemoveWS()
	}, [gameRemoveWS])

	return (
		<div className={c['page-game']}>
			<Chat />
			<GameHeader />
			<GameContent />
			<GameFooter />
		</div>
	)
}

const GamePageWithLayout = withLayout(GamePage)
export default GamePageWithLayout
