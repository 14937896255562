import type { DynamicGameData } from './game.types'

export const gameDynamicsInit: DynamicGameData = {
	round_active: false,
	table: [null, null, null, null, null],
	chairs: [null, null, null, null, null, null],

	current_dealer: 0,
	current_turn: { end_at: null, user_id: null },

	pot: 0,
	current_bet: 0,
	last_bet: 0,
	available_actions: [],

	blind_amount: 0,
	small_blind_position: 0,
	big_blind_position: 0,
	user_balance: 0
}
