import { create } from 'zustand'

import { createStoreManager } from '@/shared/utils'

import { SupportStore } from './support.types'

export const useSupportStore = create<SupportStore>(() => ({
	selectedPage: 'ranking'
}))

export const supportStoreManager = createStoreManager(useSupportStore)
