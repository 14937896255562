import { create } from 'zustand'

import { createStoreManager } from '@/shared/utils'

import { ChatStore } from './chat.types'

export const useChatStore = create<ChatStore>(() => ({
	selectedChat: 'users',

	isVisible: false,
	isSendingMsg: false,

	logs: new Set(),
	msgs: new Set()
}))

export const chatStoreManager = createStoreManager(useChatStore)
