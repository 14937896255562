import { useTranslation } from 'react-i18next'

import c from './MenuContent.module.scss'

import { PlayRandom } from './PlayRandom/PlayRandom'
import { PlayWithFriends } from './PlayWithFriends/PlayWithFriends'

export function MenuContent() {
	const { t } = useTranslation()

	return (
		<div className={c['menu-content']}>
			<PlayWithFriends />
			<h3>{t('menu_join-the-players')}</h3>
			<PlayRandom />
		</div>
	)
}
