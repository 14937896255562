import { BuyInContent } from '../BuyInContent/BuyInContent'
import { BuyInHeader } from '../BuyInHeader/BuyInHeader'

import c from './BuyInPage.module.scss'

export function BuyInPage() {
	return (
		<div className={c['page-buy-in']}>
			<BuyInHeader />
			<BuyInContent />
		</div>
	)
}
