import { useTranslation } from 'react-i18next'

import { useOverlay } from '@/components/Overlay'

import { Icon } from '@/shared/ui'
import { formatNumber } from '@/shared/utils'

import { UserCard } from '../UserCard/UserCard'
import { UserData } from '../UserData/UserData'

import c from './UserMiniProfile.module.scss'

export function UserMiniProfile() {
	const { t } = useTranslation()

	const [, setUserOverlayVisible] = useOverlay('user-mini-profile')
	const onClose = () => setUserOverlayVisible()

	const total = formatNumber(9999999)
	const wins = formatNumber(999)
	const losses = formatNumber(1000)

	return (
		<div className={c['user-mini-profile']}>
			<UserCard onClose={onClose}>
				<UserData direction='vertical' />

				<div className={c['user-statistic']}>
					<div className={c['user-statistic__label']}>
						<Icon name='star' w={15} />
						<span>{t('user-profile_statistic-label')}</span>
						<Icon name='star' w={15} />
					</div>
					<div className={c['user-statistic__total']}>
						<span>{total}</span>
						<span>{t('user-profile_games-total')}</span>
					</div>
					<div className={c['user-statistic__wins']}>
						<span>{wins}</span>
						<span>{t('user-profile_wins')}</span>
					</div>
					<div className={c['user-statistic__losses']}>
						<span>{losses}</span>
						<span>{t('user-profile_losses')}</span>
					</div>
				</div>
			</UserCard>
		</div>
	)
}
