import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Icon } from '@/shared/ui'

import c from './LobbyHeader.module.scss'

export function LobbyHeader() {
	const { t } = useTranslation()
	const nav = useNavigate()
	const hanldeBackBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		e.stopPropagation()
		nav('/menu')
	}

	return (
		<div className={c['lobby-header']}>
			<Button type='ghost' size='sm' onClick={hanldeBackBtn}>
				<Icon name='arrow-back' w={26} />
			</Button>
			<h3>{t('lobby_header-label')}</h3>
			<Button color='orange' className={c['invite-friend-btn']}>
				<div className={c.plug} />
				<Icon name='people-plus' w={40} h={30} className={c['invite-friend-btn__icon']} />
			</Button>
		</div>
	)
}
