import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useOverlay } from '@/components/Overlay'

import { Button } from '@/shared/ui'

import c from './ExitWindow.module.scss'

export function ExitWindow() {
	const { t } = useTranslation()
	const [, toggleOverlay] = useOverlay('exit-overlay')

	const nav = useNavigate()

	const onBack = () => toggleOverlay(false)
	const onQuit = () => {
		toggleOverlay(false)
		nav('/menu')
	}

	return (
		<div className={c['exit-window']}>
			<Button color='red' text={t('quit')} onClick={onQuit} />
			<Button text={t('back')} onClick={onBack} />
		</div>
	)
}
