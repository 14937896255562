import { create } from 'zustand'

import { createStoreManager } from '@/shared/utils'

import { TUser } from './user.types'

export const useUserStore = create<TUser>(() => ({
	id: 0,
	avatar: null,
	tg_uid: '',
	username: '',
	tg_username: '',
	balance: 0
}))

export const userStoreManager = createStoreManager(useUserStore)
