import { create } from 'zustand'

import { createStoreManager } from '@/shared/utils'

import { FilesStore } from './files.types'

export const useFilesStore = create<FilesStore>(() => ({
	cards: []
}))

export const filesStoreManager = createStoreManager(useFilesStore)
