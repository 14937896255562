import { UserInHeader } from '@/entities/user'

import c from './Header.module.scss'

export function Header() {
	return (
		<header className={c['main-header']}>
			<UserInHeader />
		</header>
	)
}
