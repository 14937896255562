import { enableMapSet } from 'immer'

import { RoutesProvider } from './providers/RoutesProvider'
import { TelegramEnvironment } from './providers/TelegramEnvironment'

enableMapSet()

export function App() {
	return (
		<RoutesProvider>
			<TelegramEnvironment />
		</RoutesProvider>
	)
}
