import { BuyInPage } from './BuyInPage'

export function BuyIn() {
	return (
		<div className='layout layout--with-header'>
			<div />
			<BuyInPage />
		</div>
	)
}
