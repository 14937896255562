// Actions
import * as userActions from './model/user.actions'

export const useUserActions = () => ({ ...userActions })

// Store
export { useUserStore } from './model/user.store'
export * from './model/user.types'

// UI
export { UserInGame } from './ui/UserInGame/UserInGame'
export { UserInLobby } from './ui/UserInLobby/UserInLobby'
export { UserInHeader } from './ui/UserInHeader/UserInHeader'
