import { useRouteError } from 'react-router-dom'

import c from './ErrorBoundary.module.scss'

export function ErrorBoundary() {
	const error = useRouteError()
	console.error(error)

	return (
		<div className={c['error-boundary']}>
			<h1>Dang! Error</h1>
			<pre>{JSON.stringify(error, null, 4)}</pre>
		</div>
	)
}
