import { create } from 'zustand'

import { createStoreManager } from '@/shared/utils'

import { OverlayStore } from './overlay.types'

export const useOverlayStore = create<OverlayStore>(() => ({
	overlays: new Map()
}))

export const overlayStoreManager = createStoreManager(useOverlayStore)
