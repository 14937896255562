import { CardCodeName } from '@/entities/game'

import { useFilesStore } from '../model/files.store'

export function useGetCardSprite(codeName: CardCodeName) {
	const cards = useFilesStore(s => s.cards)
	const cardIndex = cards.findIndex(c => c.name === codeName)
	if (!~cardIndex) return ''

	return cards[cardIndex].file ?? ''
}
